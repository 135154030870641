<template>
    <div class="invoice">
        <div><a href="" @click.prevent="$emit('view')">#{{ invoice.invoiceNumber }}</a></div>
        <div>{{ createdOn }}</div>
        <div class="weeks textNoWrap">{{ invoice.periods.join(', ') }}</div>
        <div class="total">${{ priceConverter(Number(invoice.total).toFixed(2)) }}</div>
        <div class="total">${{ priceConverter(Number(invoice.amountDue).toFixed(2)) }}</div>
        <div>{{ dueOn }}</div>
        <div class="status" :class="status.class">{{ status.text }}</div>
    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['invoice'],
        computed: {
            createdOn() {
                let date = moment.utc(this.invoice.createdOn);
                return date.format("Do") + " of " + date.format('MMM, YYYY');
            },
            dueOn() {
                let date = moment.utc(this.invoice.dueOn);
                return date.format("Do") + " of " + date.format('MMM, YYYY');
            },
            status() {

                let status = {
                    class: 'pending',
                    text: 'Pending'
                }

                if(this.invoice.status === 'paid') {
                    return {
                        class: 'paid',
                        text: 'Paid'
                    }
                } else if(this.invoice.status === 'cancelled') {
                    return {
                        class: 'cancelled',
                        text: 'Voided'
                    }
                }

                let start = moment().startOf('day');
                let end = moment(this.invoice.dueDate, "MM/DD/YYYY");

                var duration = moment.duration(end.diff(start));
                let days = Number(duration.asDays().toFixed(0));

                if(days >= 1) {
                    return {
                        class: 'due',
                        text: `Due in ${days} ${ days == 1 ? 'day' : 'days' }`
                    }
                } else if(days == 0) {
                    return {
                        class: 'due',
                        text: `Due today`
                    }
                } else {
                    days = days * (-1);
                    return {
                        class: 'overdue',
                        text: `Overdue by ${days} ${ days == 1 ? 'day' : 'days' }`
                    }
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
.invoice {
    position: relative;
    background: $bgSecondary;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 75px 150px minmax(0, 1fr) 100px 100px 150px 150px;
    font-size: 13px;
    min-width: 1160px;
    &:hover {
        z-index: 1;
        box-shadow: $boxShadow;
    }
}

.weeks {
    font-size: 12px;
}

.status {
    text-align: center;
    &.due {
        color: $buttonColor;
    }
    &.paid {
        color: #229954;
    }
    &.overdue {
        color: #CB4335;
    }
    &.cancelled {
        color: #B2BABB;
    }
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}

</style>