<template>
    <div>
        
        <ModalSimple v-model="invoiceToView.modal" size="large" :loading="isLoading(['getInvoiceDetails'])" confirm="Done" @confirm="invoiceToView.modal = false" :title="`${invoiceToView.item.invoiceNumber ? 'Invoice #' + invoiceToView.item.invoiceNumber : 'Invoice'}`">

            <div class="invoiceDetails">

                <div class="grid col-2 cg-10 invoiceDates">
                    <div class="row">
                        <div class="name">Invoice date</div>
                        <div class="value">{{ invoiceDate }}</div>
                    </div>

                    <div class="row">
                        <div class="name">Due date</div>
                        <div class="value">{{ dueDate }}</div>
                    </div>
                </div>

                <div class="invoiceItemList">
                    <div class="itemsTitle">Invoice items</div>
                    <div class="items" v-if="invoiceToView.item.items">
                        <div class="item header">
                            <div>Name</div>
                            <div>Description</div>
                            <div>Quantity</div>
                            <div>Rate</div>
                            <div>Amount</div>
                        </div>
                        <div class="item" :key="key" v-for="(item, key) of invoiceToView.item.items">
                            <div class="name">{{ item.service }}</div>
                            <div class="description">{{ item.description }}</div>
                            <div class="quantity">${{ priceConverter(Number(item.quantity).toFixed(2)) }}</div>
                            <div class="rate">{{ item.rate }}</div>
                            <div class="amount">${{ priceConverter(Number(item.amount).toFixed(2)) }}</div>
                        </div>
                    </div>
                </div>

                <div class="total">Total to pay: <strong>${{ priceConverter(Number(invoiceToView.item.total).toFixed(2)) }}</strong></div>


                <div class="row" v-if="invoiceToView.item.message">
                    <div class="name">Message</div>
                    <div class="value">{{ invoiceToView.item.message }}</div>
                </div>

                <div class="row">
                    <div class="name">Action</div>
                    <div class="value actionBtns">
                        <a :href="invoiceUrl" target="_blank">View invoice <span><i class="fa-solid fa-eye"></i></span></a>
                        <a :href="invoicePDFurl"  target="_blank">Download invoice <span><i class="fa-solid fa-floppy-disk"></i></span></a>
                    </div>
                </div>


            </div>

        </ModalSimple>

        <div class="invoicesContent">


            <div class="invoicesList">


                <div class="title">
                    <h2>My Invoices • {{ invoices.total ? invoices.total : 0 }}</h2>
                </div>

                <div class="stats" v-if="invoices && invoices.stats">
                    <div class="statsGroup">
                        <div class="_title"><i class="fas fa-dollar-sign"></i><span>Total amount</span></div>
                        <div class="_value">${{ priceConverter(invoices.stats.total) }}</div>
                    </div>

                    <div class="statsGroup">
                        <div class="_title"><i class="fas fa-dollar-sign"></i><span>Paid amount</span></div>
                        <div class="_value">${{ priceConverter(invoices.stats.paid) }}</div>
                    </div>

                    <div class="statsGroup">
                        <div class="_title"><i class="fas fa-dollar-sign"></i><span>Overdue amount</span></div>
                        <div class="_value">${{ priceConverter(invoices.stats.overdue) }}</div>
                    </div>

                    <div class="statsGroup">
                        <div class="_title"><i class="fas fa-dollar-sign"></i><span>Due amount</span></div>
                        <div class="_value">${{ priceConverter(invoices.stats.due) }}</div>
                    </div>
                </div>

                <div class="invoicesListHolder" v-if="invoices && invoices.overdue && invoices.overdue.length > 0 && settings.status != 'overdue'">
                    <div class="title"><h2>Overdue invoices</h2></div>
                    <div class="list" v-if="invoices && invoices.overdue && invoices.overdue.length > 0">
                        <div class="listHeader">
                            <div>Invoice #</div>
                            <div>Date</div>
                            <div>Periods</div>
                            <div>Total</div>
                            <div>Amount Due</div>
                            <div>Due Date</div>
                            <div style="text-align: center">Status</div>
                        </div>
                        <InvoiceItem  @dblclick="viewInvoice(invoice)" @view="viewInvoice(invoice)" :key="invoice._id" v-for="invoice of invoices.overdue" :invoice="invoice"/>
                    </div>
                </div>

                <div class="invoicesListHolder">

                    <div class="title">
                        <h2>All invoices</h2>
                        <div class="statusSelector">
                            <button :key="key" class="option" :class="{ 'active' : key === settings.status }" @click="settings.status = key" v-for="(status, key) of statuses">{{ status.text }}</button>
                        </div>
                    </div>

                    <div class="list" v-if="invoices && invoices.list && invoices.list.length > 0">
                        <div class="listHeader">
                            <div>Invoice #</div>
                            <div>Date</div>
                            <div>Periods</div>
                            <div>Total</div>
                            <div>Amount Due</div>
                            <div>Due Date</div>
                            <div style="text-align: center">Status</div>
                        </div>
                        <InvoiceItem @dblclick="viewInvoice(invoice)" @view="viewInvoice(invoice)" :key="invoice._id" v-for="invoice of invoices.list" :invoice="invoice"/>
                    </div>

                    <div class="noInvoicesMessage" v-else>
                        <div class="icon"><i class="fa-solid fa-file-invoice-dollar"></i></div>
                        <div class="message">There are no invoices to view yet.</div>
                    </div>

                    <div class="pagination" v-if="invoices.list && invoices.pages && invoices.pages > 1">
                        <button class="arrow" :disabled="settings.page <= 1" @click="nextPage(-1)"><i class="fa-solid fa-arrow-left"></i></button>
                        <div class="pageNum">{{ settings.page }}</div>
                        <button class="arrow" @click="nextPage(1)" :disabled="settings.page >= invoices.pages"><i class="fa-solid fa-arrow-right"></i></button>
                    </div>

                </div>

            </div>

        </div>
        
    </div>
</template>

<script>
import InvoiceItem from '../components/InvoiceItem.vue'
import moment from 'moment';

    export default {
        components: {
            InvoiceItem
        },
        computed: { 
            invoiceDate() {
                if(!this.invoiceToView.item.createdOn) return;
                let date = moment.utc(this.invoiceToView.item.createdOn);
                return date.format("Do") + " of " + date.format('MMM, YYYY');
            },
            dueDate() {
                if(!this.invoiceToView.item.dueOn) return;
                let date = moment.utc(this.invoiceToView.item.dueOn);
                return date.format("Do") + " of " + date.format('MMM, YYYY');
            },
            invoiceUrl() {
                return (process.env.VUE_APP_BOARD_FRONTEND_URL || 'https://example.com') + '/invoice/' + this.invoiceToView.item._id;
            },
            invoicePDFurl() {
                return (process.env.VUE_APP_BOARD_API_URL || 'https://example.com') + '/invoice/DownloadPDF/' + this.invoiceToView.item._id;
            }
        },
        data() {
            return {
                invoices: {},
                settings: {
                    page: 1,
                    status: 'all',
                    dateStart: moment.utc().subtract(10, 'year').format('MM/DD/YYYY')
                },
                statuses: {
                    "all": { text: 'All', value: '' },
                    "paid": { text: 'Paid', value: '' },
                    "due": { text: 'Due', value: '' },
                    "overdue": { text: 'Overdue', value: '' },
                    "cancelled": { text: 'Void', value: ''}
                },
                invoiceToView: {
                    modal: false,
                    item: {}
                }
            }
        },
        methods: {
            getInvoiceDetails() {
                if(!this.invoiceToView.item._id) return;
                this.ajax('getInvoiceDetails', {
                    url: `/myInvoices/${this.invoiceToView.item._id}`,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.invoiceToView.item = body;
                });
            },
            viewInvoice(item) {
                this.invoiceToView.item = item;
                this.invoiceToView.modal = true;
            },
            getInvoices() {

                let params = new URLSearchParams(this.settings).toString();

                this.ajax('getListOfInvoices', {
                    url: `/myInvoices?${params}`,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.invoices = body;
                });
            },
            nextPage(num) {
                this.settings.page += num;
            }
        },
        mounted() {
            this.getInvoices();
        },
        watch: {
            settings: {
                deep: true,
                handler() {
                    this.getInvoices();
                }
            },
            invoices() {
                if(this.settings.page > this.invoices.pages) {
                    this.settings.page = 1;
                }
            },
            "invoiceToView.modal"() {
                if(this.invoiceToView.modal) this.getInvoiceDetails();
            }
        }
    }
</script>

<style lang="scss" scoped>
.invoicesContent {
    padding: 30px 0;
}

.invoiceItemList {
    overflow-x: auto;
}

.invoicesList {
    display: grid;
    row-gap: 20px;
}

.itemsTitle {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
}

.items {
    border-radius: $borderRadius;
    border: 1px solid $borderColor;
    background: $bgSecondary;
    overflow-x: auto;
    .item {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 100px 50px 100px;
        column-gap: 10px;
        height: 34px;
        align-items: center;
        font-size: 12px;
        padding: 0 10px;
        border-bottom: 1px solid $borderColor;
        position: relative;
        z-index: 1;
        background: $bgSecondary;
        width: 688px;
        &:hover {
            z-index: 2;
            box-shadow: $boxShadow;
        }
        &:last-child {
            border: 0;
        }
        &.header {
            background: $bgSecondary;
            border-radius: $borderRadius $borderRadius 0 0;
            box-shadow: $boxShadow;
            height: 44px;
            text-transform: uppercase;
            font-weight: bold;
            border-bottom: 1px solid $borderColor;
        }
    }
}

.invoiceDetails {
    display: grid;
    row-gap: 20px;
}

.actionBtns {
    display: grid;
    width: 100%;
    max-width: 400px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    a {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        padding: 0 20px;
        text-align: left;
        height: 44px;
        align-items: center;
        background: $bgSecondary;
        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        border: 1px solid $borderColor;
        font-weight: bold;
        transition: ease 0.3s;
        &:hover {
            transform: scale(1.02);
            color: $buttonColor;
        }
    }
}

.row {
    display: grid;
    row-gap: 5px;
    .name {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .value {
        font-size: 14px;
    }
}


.statusSelector {
    height: 34px;
    box-shadow: $boxShadow;
    background: $bgSecondary;
    border-radius: 5px;
    .option {
        background: transparent;
        border: 0;
        padding: 0 20px 0 20px;
        position: relative;
        height: 100%;
        transition: ease 0.3s;
        text-transform: uppercase;
        font-size: 13px;
        border-right: 1px solid $borderColor;
        &:last-child {
            border: 0;
        }
        &::before {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            content: '';
            width: 0;
            height: 0;
            background: $buttonColor;
            transition: ease 0.3s;
            transform-origin: 50%;
            border-radius: 50%;
        }
        &.active {
            color: $buttonColor;
            padding: 0 20px 0 45px;
            &::before {
                width: 5px;
                height: 5px;
            }
        }
    }
}

.invoicesListHolder {
    display: grid;
    row-gap: 20px;
    .list {
        overflow-x: auto;
    }
}

.listHeader {
    display: grid;
    column-gap: 20px;
    padding: 20px;
    min-width: 1160px;
    grid-template-columns: 75px 150px minmax(0, 1fr) 100px 100px 150px 150px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    background: $bgSecondary;
    border-bottom: 1px solid $borderColor;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.noInvoicesMessage {
    padding: 100px 30px;
    display: grid;
    row-gap: 30px;
    align-items: center;
    justify-items: center;
    background: $bgSecondary;
    border-radius: $borderRadius;
    .icon {
        font-size: 50px;
    }
    .message {
        text-align: center;
        font-size: 14px;
        opacity: 0.8;
    }
    &.small {
        padding: 30px;
        .icon {
            font-size: 30px;
        }
    }
}

.stats {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;
}

.statsGroup {
    ._value {
        font-size: 22px;
    }
}


.pagination {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 44px 50px 44px;
    column-gap: 10px;
    .pageNum {
        text-align: center;
        font-size: 20px;
    }
    .arrow {
        width: 44px;
        height: 44px;
        border: 1px solid $borderColor;
        background: transparent;
        border-radius: 50%;
        font-size: 16px;
        color: $text;
        transition: ease 0.3s;
        &:hover {
            background: $buttonColor;
            color: #fff;
        }
        &:disabled {
            background: $borderColor;
            color: inherit;
            cursor: not-allowed;
        }
    }
}


@media only screen and (max-width: 992px) {
    .stats {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
}

@media only screen and (max-width: 700px) {
    .title {
        display: grid;
        row-gap: 20px;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }
    
    .statusSelector {
        background: transparent;
        box-shadow: none;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        .option {
            padding: 0 10px;
            font-size: 12px;
            border: 0;
            margin-right: 5px;
            margin-bottom: 5px;
            border: 1px solid $borderColor;
            height: 34px;
            border-radius: $borderRadius;
            &:last-child {
                border: 1px solid $borderColor;
            }
            &.active {
                border: 1px solid $buttonColor;
                padding: 0 10px;
                &::before {
                    content: none;
                }
            }
        }
    }
}




@media only screen and (max-width: 600px) {
    .stats {
        grid-template-columns: minmax(0, 1fr);
    }
    .invoiceDates {
        row-gap: 20px !important;
        grid-template-columns: minmax(0, 1fr) !important;
    }
    .actionBtns {
        max-width: 100%;
        grid-template-columns: minmax(0, 1fr);
        row-gap: 10px;
    }
}
</style>